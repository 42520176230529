import React, { useState, useEffect } from 'react';
import styles from './GeneralOverview.module.css';
import { BASE_URL, PH_GENERAL_OVERVIEW, BLUR_OPTIONS } from '../../constants/constants';
import { shouldBlurContent } from '../../utils/helpers';

const GeneralOverview = ({ modelState }) => {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedOption, setSelectedOption] = useState('BTC');
  const [selectedTimeframe, setSelectedTimeframe] = useState('high');
  const [isOpen, setIsOpen] = useState(false);
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);

  const [data, setData] = useState([]);
  const isBlurred = shouldBlurContent(selectedOption, selectedTimeframe);

  const [timeframes, setTimeframes] = useState();

  const handleClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink('https://dashboard.yoracle.ai/');
    } else {
      window.open('https://dashboard.yoracle.ai/', '_blank', 'noopener,noreferrer');
    }
  };

  useEffect(() => {
    setData(PH_GENERAL_OVERVIEW);
  }, []);

  useEffect(() => {
    if (modelState) {
      const extractTimeframes = () => {
        return Object.keys(Object.values(Object.values(modelState)[0])[0] || {});
      };

      const availableTimeframes = extractTimeframes();
      setTimeframes(availableTimeframes);
      setSelectedTimeframe('high');
    }
  }, [modelState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = modelState;
        const projectIds = Object.keys(Object.values(result)[0]);

        setProjectIds(projectIds);
        setSelectedOption(selectedOption ? selectedOption : projectIds[0]);

        if (BLUR_OPTIONS.projects.visible.includes(selectedOption) && BLUR_OPTIONS.timeframes.visible.includes(selectedTimeframe)) {
          const formattedData = Object.keys(result).map((label) => {
            const residual = result[label][selectedOption]?.[selectedTimeframe]?.residual;
            return {
              // label: `${label} high`,
              label,
              value: residual !== undefined ? `${residual}%` : '0%',
              trend: residual !== undefined ? (residual > 0 ? 'up' : 'down') : 'N/A',
            };
          });
          setData(formattedData.slice(0, 9));
        } else {
          setData(PH_GENERAL_OVERVIEW);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (modelState) {
      fetchData();
    }
  }, [selectedOption, selectedTimeframe, modelState]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (projectId) => {
    setSelectedOption(projectId);
    setIsOpen(false);
  };

  const toggleTimeframeDropdown = () => {
    setIsTimeframeOpen(!isTimeframeOpen);
  };

  const selectTimeframe = (timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsTimeframeOpen(false);
  };

  // const handleCardClick = (model) => {
  //   // setModel(model);
  //   setProjectID(selectedOption);
  //   navigate("/model");
  // };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>General Overview </div>
            {/* <Tooltip /> */}
          </div>

          <div className={styles.dropdownWrapper}>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleDropdown}>
                <span className={styles.selectedOption}>
                  {/* <img src={`/images/${selectedOption.toLowerCase()}.svg`} alt={selectedOption} /> */}
                  {selectedOption}
                </span>
                <span className={styles.arrow}>
                  {isOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isOpen && (
                <div className={styles.dropdownMenu}>
                  {projectIds.map((option) => (
                    <div key={option} className={styles.dropdownItem} onClick={() => selectOption(option)}>
                      {/* <img src={`/images/${option.toLowerCase()}.svg`} alt={option} /> */}
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleTimeframeDropdown}>
                <span className={styles.selectedOption}>{selectedTimeframe}</span>
                <span className={styles.arrow}>
                  {isTimeframeOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isTimeframeOpen && (
                <div className={styles.dropdownMenu}>
                  {timeframes.map((timeframe) => (
                    <div key={timeframe} className={styles.dropdownItem} onClick={() => selectTimeframe(timeframe)}>
                      {timeframe}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.cardContainerWrapper}>
          <div className={`${styles.cardContainer} ${isBlurred ? styles.blurredCard : ''}`}>
            {data.map((item, index) => (
              <div key={index} className={styles.card}>
                {item.value !== '0%' && (
                  <div className={styles.gradient}>
                    <img src={item.trend === 'up' ? '/images/cardBgGreen.png' : '/images/cardBgRed.svg'} alt='card background' />
                  </div>
                )}
                <div className={styles.cardContentContainer}>
                  <div className={styles.cardDetails}>
                    <div
                      className={item.value === '0%' ? styles.valueGrey : item.trend === 'up' ? styles.valueGreen : styles.valueRed}
                      style={{
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}>
                      {item.value}
                    </div>
                    <div className={styles.collectionName}>
                      <div className={styles.label}>{item.label}</div>
                      {item.value !== '0%' && (
                        <div className={styles.graph}>
                          <img src={item.trend === 'up' ? '/images/graphGreen.svg' : '/images/graphred.svg'} alt='graph' />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isBlurred && selectedOption && (
            <div className={styles.btnWrapper}>
              <button onClick={handleClick} className={styles.button}>
                View on Dashboard
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneralOverview;
