import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import styles from "./Home.module.css";
import GeneralOverview from "../GeneralOverview/GeneralOverview.js";
import StateHeatmap from "../Heatmap/StateHeatmap.js";
import Technicals from "../Technicals/Technicals.js";
import ModelSentiment from "../ModelSentiment/ModelSentiment.js";
import SliderHeader from "../SliderHeader/SliderHeader.jsx";
import Ads from "../Ads/Ads.js";
import { BASE_URL } from "../../constants/constants.js";
import "./slider.css";

const Home = () => {
  const [modelState, setModelState] = useState();

  useEffect(() => {
    const fetchModelState = async () => {
      try {
        const response = await fetch(`${BASE_URL}/public_ms`);
        const result = await response.json();

        setModelState(result);
      } catch (error) {
        console.error("Error fetching dashboard state:", error);
      }
    };

    fetchModelState();
  }, []);
  return (
    <>
      <SliderHeader />
      <div className={styles.sliderContainer}>
        {/* <div className={styles.border}> */}
        <div className={styles.slider}>
          <Swiper
            className="aiPowered"
            slidesPerView={1}
            navigation={true}
            loop={true}
            // autoHeight={true}
            // autoplay={{
            //   delay: 5500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
          >
            <SwiperSlide>
              <div className={styles.Mcard}>
                <GeneralOverview modelState={modelState} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.Mcard}>
                <ModelSentiment modelState={modelState} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`${styles.Mcard} ${styles.ads}`}>
                <Ads />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ minHeight: "450px" }}>
              <div className={styles.Mcard}>
                <StateHeatmap modelState={modelState} />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className={styles.Mcard}>
                <Technicals modelState={modelState} />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Home;
