import { BLUR_OPTIONS } from "../constants/constants";

export const shouldBlurContent = (
  selectedProjects = null,
  selectedTimeframe = null
) => {
  if (selectedProjects && !selectedTimeframe) {
    return !BLUR_OPTIONS.projects.visible.includes(selectedProjects);
  }
  if (!selectedProjects && selectedTimeframe) {
    return !BLUR_OPTIONS.timeframes.visible.includes(selectedTimeframe);
  }
  if (selectedProjects && selectedTimeframe) {
    const isValidAsset =
      BLUR_OPTIONS.projects.visible.includes(selectedProjects);
    const isValidTimeframe =
      BLUR_OPTIONS.timeframes.visible.includes(selectedTimeframe);
    return !(isValidAsset && isValidTimeframe);
  }
  return false;
};
