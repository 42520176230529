import React from "react";
import styles from "./SliderHeader.module.css";
import { TELEGRAM_DOCS_URL, TELEGRAM_BOT_URL } from "../../constants/constants";

const SliderHeader = () => {

  const handleDocsClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(TELEGRAM_DOCS_URL);
    } else {
      window.open(TELEGRAM_DOCS_URL, "_blank", "noopener");
    }
  };

  const handleBotClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(TELEGRAM_BOT_URL);
    } else {
      window.open(TELEGRAM_BOT_URL, "_blank", "noopener");
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoWrapper}>
        <img src="/images/yaiLogo.svg" />{" "}
      </div>
      <div className={styles.btnWrapper}>
        <button onClick={handleDocsClick} className={styles.docsBtn}>
          Info
        </button>
        <button onClick={handleBotClick} className={styles.docsBtn}>
          Ÿ Agent
        </button>
      </div>
    </div>
  );
};

export default SliderHeader;
