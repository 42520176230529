import React, { useState, useEffect } from 'react';
import styles from './ModelSentiment.module.css';
import Circle from './Circle.js';
import { shouldBlurContent } from '../../utils/helpers';
import { BLUR_OPTIONS } from '../../constants/constants';

function ModelSentiment({ modelState }) {
  const [projectIds, setProjectIds] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState('high');
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);
  const [sentimentData, setSentimentData] = useState({});
  const [timeframes, setTimeframes] = useState([]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleTimeframeDropdown = () => {
    setIsTimeframeOpen(!isTimeframeOpen);
  };
  const selectProjectId = (projectId) => {
    setSelectedProjectId(projectId);
    setIsOpen(false);
  };

  const selectTimeframe = (timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsTimeframeOpen(false);
  };

  useEffect(() => {
    if (modelState) {
      const extractProjectIds = (modelState) => {
        const allModels = Object.keys(modelState);
        const allProjectIds = [...new Set(allModels.flatMap((model) => Object.keys(modelState[model])))];
        return Array.from(allProjectIds);
      };

      const uniqueProjectIds = extractProjectIds(modelState);
      setProjectIds(uniqueProjectIds);
      if (uniqueProjectIds.length > 0) {
        setSelectedProjectId(uniqueProjectIds[0]);
      } else {
        setSelectedProjectId(null);
      }
    } else {
      setProjectIds([]);
      setSelectedProjectId(null);
      setSentimentData({});
      setIsLoading(false);
    }
  }, [modelState]);

  useEffect(() => {
    if (modelState) {
      const extractTimeframes = () => {
        return Object.keys(Object.values(Object.values(modelState)[0])[0] || {});
      };

      const availableTimeframes = extractTimeframes();
      setTimeframes(availableTimeframes);
      setSelectedTimeframe('high');
    }
  }, [modelState]);

  useEffect(() => {
    if (modelState && BLUR_OPTIONS.timeframes.visible.includes(selectedTimeframe) && BLUR_OPTIONS.projects.visible.includes(selectedProjectId)) {
      const fetchData = async () => {
        try {
          const result = modelState;
          const data = {};

          Object.entries(result).forEach(([model, projectData]) => {
            Object.entries(projectData).forEach(([projectId, timeframeData]) => {
              const { z_residual } = timeframeData[selectedTimeframe] || {};

              if (z_residual !== undefined) {
                if (!data[projectId]) {
                  data[projectId] = {
                    bullish: 0,
                    neutral: 0,
                    bearish: 0,
                    models: {},
                  };
                }
                if (z_residual > 1.5) data[projectId].bullish++;
                else if (z_residual < -1.5) data[projectId].bearish++;
                else data[projectId].neutral++;

                data[projectId].models[model] = {
                  z_score: z_residual,
                  sentiment: getSentiment(z_residual),
                  timeframe: selectedTimeframe,
                };
              }
            });
          });

          setSentimentData(data);
        } catch (error) {
          console.error('Error processing data:', error);
          setSentimentData({});
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setIsLoading(false);
      setSentimentData({});
    }
  }, [modelState, selectedProjectId, selectedTimeframe]);

  const getSentiment = (zScore) => {
    if (zScore > 1.5) return 'Bullish';
    if (zScore < -1.5) return 'Bearish';
    return 'Neutral';
  };

  const isBlurred = shouldBlurContent(selectedProjectId, selectedTimeframe);

  const handleClick = () => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink('https://dashboard.yoracle.ai/');
    } else {
      window.open('https://dashboard.yoracle.ai/', '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.circleBG}>
        <img src='/images/circleBG.png' alt='' />
      </div>{' '}
      <div className={styles.subContainer}>
        <div className={styles.overviewHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>Models By Sentiment</div>
          </div>

          <div className={styles.dropdownsWrapper}>
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleDropdown}>
                <span className={styles.selectedOption}>{selectedProjectId}</span>
                <span className={styles.arrow}>
                  {isOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isOpen && (
                <div className={styles.dropdownMenu}>
                  {projectIds.map((projectId) => (
                    <div key={projectId} className={styles.dropdownItem} onClick={() => selectProjectId(projectId)}>
                      {projectId}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.dropdownContainer}>
              <div className={styles.dropdown} onClick={toggleTimeframeDropdown}>
                <span className={styles.selectedOption}>{selectedTimeframe}</span>
                <span className={styles.arrow}>
                  {isTimeframeOpen ? (
                    <img className={styles.invertedArrow} src='/images/arrowDown.svg' alt='arrow down' />
                  ) : (
                    <img src='/images/arrowDown.svg' alt='arrow down' />
                  )}
                </span>
              </div>
              {isTimeframeOpen && (
                <div className={styles.dropdownMenu}>
                  {timeframes.map((timeframe) => (
                    <div key={timeframe} className={styles.dropdownItem} onClick={() => selectTimeframe(timeframe)}>
                      {timeframe}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.circleContainerWrapper}>
          <div className={`${styles.circleContainer} ${isBlurred ? styles.blurredCard : ''}`}>
            <Circle
              sentimentCounts={
                sentimentData[selectedProjectId] || {
                  bullish: 0,
                  neutral: 0,
                  bearish: 0,
                }
              }
              projectData={sentimentData[selectedProjectId]?.models || {}}
            />
          </div>

          {isBlurred && selectedProjectId && (
            <div className={styles.btnWrapper}>
              <button onClick={handleClick} className={styles.button}>
                View on Dashboard
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModelSentiment;
